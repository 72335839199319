<template>
  <div>
    <b-button variant="outline-primary" @click.prevent="displayInfo()">
      <font-awesome-icon :icon="['far', 'question-circle']" size="2x" />
    </b-button>
    <b-modal
      id="info-modal"
      v-model="info"
      size="xl"
      centered
      ok-only
      :title="title"
    >
      <p v-html="text" />
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: false,
    }
  },
  computed: {
    title() {
      return this.$route.name ? this.$t("info." + this.$route.name.toLowerCase() + ".title") : null
    },
    text() {
      return this.$route.name ? this.$t("info." + this.$route.name.toLowerCase() + ".text") : null
    },
  },
  methods: {
    displayInfo() {
      this.info = true;
    }
  }
}
</script>

<style lang="scss" scoped>
#info-modal {
  padding: 4em;
}

button {
  border: none;
  border-radius: 100px;
  padding: 1px;
  line-height: 0;
}
</style>
